<template>
<div>
  <v-row class="d-flex justify-center mt-0 mx-md-0 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 pb-0 px-1">
      <div v-if="!nuevo"
        style="font-size: 18px;" 
        v-text="`Factura de Compra N°: ${factura_id}`"
      ></div>
      <v-btn class="ml-2" icon  @click="subirArchivo(datosFactura)"  v-if="!nuevo" :disabled="!editar">
        <v-icon :color="parseInt(datosFactura.con_archivo) > 0 ? 'success' : 'error'" title="Subir Archivos">
          fas fa-cloud-upload-alt
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nuevo && existe && permisoEditar == 1"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch><!--&& articulo_copy.inhabilitado != 1-->
      </v-expand-x-transition>
    </v-col>
    <!-- Encabezado -->
    <v-col cols="12">
      <v-form ref="formEncabezado">
        <v-row class="pt-0">
          <v-col cols="12" sm="6" md="3" class="py-1">
            Empresa
            <v-autocomplete
              v-model="datosFactura.empresa_codigo"
              item-text="nombre_corto"
              item-value="id"
              :items="empresas"
              hide-details
              outlined
              dense
              :readonly="!nuevo"
              :filled="!nuevo"
              @change="getProveedores()"
              :clearable="nuevo"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" :md="multiCuentaProv.length>0 ? '3' : '3'" class="py-1">
            Sucursal
            <v-autocomplete
              v-model="datosFactura.sucursal_codigo"
              item-text="nombre"
              item-value="id"
              :items="sucursales"
              hide-details
              outlined
              dense
              :clearable="nuevo || (editar && !nuevo)"
              :readonly="!editar && !nuevo"
              :filled="!editar && !nuevo"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" :md="multiCuentaProv.length>0 ? '3' : '6'" class="py-1">
            Proveedor
            <div class="d-flex">
              <v-tooltip color="primary" left v-if="Object.keys(selectedProveedor).length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                    class="mr-2"
                    style="cursor: pointer"
                    small
                  >
                    fas fa-info-circle
                  </v-icon>
                </template>
                <div>
                  Cuit: <strong style="margin-left: 17px">{{selectedProveedor.proveedor_cuit}}</strong>
                </div>  
              </v-tooltip>
              <v-autocomplete
                v-model="selectedProveedor"
                item-text="proveedor_nombre"
                :items="proveedores"
                hide-details
                outlined
                dense
                :readonly="!nuevo"
                :filled="!nuevo"
                return-object
                :clearable="nuevo"
                @change="verifyMultiCuenta()"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-1" v-if="multiCuentaProv.length > 0">
            Proveedor Bejerman
            <v-autocomplete
              v-model="datosFactura.proveedor_bejerman_codigo"
              item-text="proveedor_nombre"
              item-value="proveedor_codigo"
              :items="multiCuentaProv"
              hide-details
              outlined
              dense
              clearable
              :readonly="!editar && !nuevo"
              :filled="!editar && !nuevo"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2" md="2" class="py-1">
            Fecha
            <FechaPickerVue
              class=""
              hide-details
              v-model="datosFactura.fecha"
              :readonly="!editar && !nuevo"
              :filled="!editar && !nuevo"
              :disabled="!editar && !nuevo"
              :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
            />
          </v-col>
          <v-col cols="12" sm="4" :md="facturasCompraAsociada.length>0? '3' : '3'"  class="py-1">
            Tipo Comprobante
            <v-autocomplete
              v-model="datosFactura.tipo_factura_id"
              item-value="tipo_id"
              item-text="tipo_nombre"
              :items="tiposFactura"
              hide-details
              outlined
              dense
              key="id_key"
              :clearable="nuevo || editar"
              :readonly="!nuevo && !editar"
              :filled="!nuevo && !editar"
              @change="getFactComprobante()" 
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" :md="datosFactura.tipo_factura_id ? '4' : '4'" class="py-1" 
            v-if="selectedTipoFactura.comp_tipo_id == 8 || selectedTipoFactura.comp_tipo_id == 9">
            Factura Comprobante
            <v-autocomplete
              v-model="selectedFactCompraAsoc"
              item-text="factura"
              :items="facturasCompraAsociada"
              hide-details
              outlined
              dense
              clearable
              return-object
              :readonly="!editar && !nuevo"
              :filled="!editar && !nuevo"
              :disabled="!editar && !nuevo"
              @change="getFacturaAsoc()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="py-1">
            Referencia
            <v-text-field
              v-model="datosFactura.referencia"
              outlined
              dense
              :clearable="nuevo || editar"
              :readonly="!editar"
              :filled="!editar"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1">
            Emision
            <v-text-field
              v-model="datosFactura.emision"
              outlined
              dense :clearable="nuevo || editar"
              type="number"
              :readonly="!editar && !nuevo"
              :filled="!editar && !nuevo"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1">
            Numero
            <v-text-field
              v-model="datosFactura.numero"
              outlined
              dense :clearable="nuevo || editar"
              type="number"
              :readonly="!editar && !nuevo"
              :filled="!editar && !nuevo"
              hide-details
            ></v-text-field>
          </v-col>
        <!-- </v-row>
        <v-row> -->
          <v-col cols="12" sm="2" md="2" class="py-1">
            Importe Total
            <TextFieldMoneyVue
              :precision="4"
              v-model="datosFactura.total"
                @blur="setEncImpTotal()"
                v-bind:properties="{
                  prefix: '$',
                  'hide-details': true,
                  'validate-on-blur': true,
                  'readonly': true,
                  'filled': true,
                }"
            />
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1">
            Moneda
            <v-autocomplete
              v-model="datosFactura.moneda_id"
              item-text="moneda_nombre"
              item-value="moneda_id"
              :items="monedas"
              hide-details
              outlined
              dense
              :clearable="nuevo ? (selectedTipoFactura.comp_tipo_id != 8 && selectedTipoFactura.comp_tipo_id != 9) : false"
              :readonly="nuevo ? (selectedTipoFactura.comp_tipo_id == 8 || selectedTipoFactura.comp_tipo_id == 9) : true"
              :filled="nuevo ? (selectedTipoFactura.comp_tipo_id == 8 || selectedTipoFactura.comp_tipo_id == 9) : true"
              @change="verificarCotizacion()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2" v-if="datosFactura.moneda_id == 2" class="py-1">
            Cotización
            <TextFieldMoneyVue
              :precision="4"
              v-model="datosFactura.cotizacion" 
              v-bind:properties="{
                prefix: '$',
                'clearable' : nuevo ? true : (editar ? true : false),
                'hide-details': true,
                'validate-on-blur': true,
                'readonly': nuevo ? ((selectedTipoFactura.comp_tipo_id == 8 || selectedTipoFactura.comp_tipo_id == 9) && this.datosFactura.fc_asoc != 0) : (editar ? false : true),
                'filled': nuevo ? ((selectedTipoFactura.comp_tipo_id == 8 || selectedTipoFactura.comp_tipo_id == 9) && this.datosFactura.fc_asoc != 0) : (editar ? false : true),
              }"
            />
          </v-col>
          <v-col cols="12" sm="2" md="2" v-if="datosFactura.moneda_id == 2 && selectedTipoFactura.comp_tipo_id == 9" class="py-1">
            Nueva Cotización
            <TextFieldMoneyVue
              :precision="4" clearable
              v-model="datosFactura.new_cotizacion"
                @blur="getArtNewCot()"
                v-bind:properties="{
                  prefix: '$',
                  'clearable' : true,
                  'hide-details': true,
                  'validate-on-blur': true,
                  'readonly': !editar,
                  'filled': !editar,
                }"
            />
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="!nuevo">
            Estado Control
            <v-autocomplete
              v-model="datosFactura.estado_control_id"
              item-text="estado_control_nombre"
              item-value="estado_control_id"
              :items="estadosControl"
              hide-details
              outlined
              dense
              :readonly="!editar"
              :filled="!editar"
              @change="controlEstado()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="3" class="py-1">
            Fecha Imp Costos
            <div class="d-flex">
              <FechaPickerVue
                class=""
                hide-details
                v-model="datosFactura.fecha_costos"
                :readonly="!editar && !nuevo"
                :filled="!editar && !nuevo"
                :disabled="!editar && !nuevo"
                :fecha_minima="moment(fecha_costos_minima).format('DD/MM/YYYY')"
                :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
              />
              <v-tooltip color="primary" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                    class="mr-2"
                    style="cursor: pointer"
                    small
                  >
                    fas fa-question-circle
                  </v-icon>
                </template>
                <div>
                  Los costos de la factura se imputarán con esta fecha indicada
                </div>  
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <!-- Modal Subir Archivos -->
    <ModalSubirArchivosVue
        :ruta="ruta_carpeta"
        :activo="dialogSubirArchivo"
        :facturaId="facturaSeleccionada"
        @cerrarModalSA="closeModalSubirArchivo()"
    />
    <!-- Seleccionar Tipo Origin -->
    <v-col 
      cols="12" class="pt-1 pb-0 d-flex align-center"
      v-if="(selectedTipoFactura.comp_tipo_id != 8 && selectedTipoFactura.comp_tipo_id != 9) && (this.selectedTipoFactura != null && Object.keys(this.selectedTipoFactura).length > 0)">
      <!-- <p class="py-0">Origen</p> -->
      <div class="py-0 mr-2">Origen:</div>
      <v-radio-group
        v-model="origen"
        row
        @change="getOrigen()"
        :disabled="!nuevo && origenesFacturaCompra.length != 0"
        class="py-0"
      >
        <v-radio
          label="Recepción"
          value="recepcion"
        ></v-radio>
        <v-radio
          label="Orden de Compra"
          value="orden-de-compra"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <!-- cargar importar Recepciones y Ordenes -->
    <v-col cols="12" v-if="origen || !nuevo">
      <v-row>
        <v-col cols="12" sm="6" md="6" class="pt-0 pr-0">
          <v-btn color="success" @click="abrirModalTodas()" :disabled="origenesFacturaCompra.length != 0 || !editar">
            {{ origen == 'recepcion'? 'BUSCAR RECEPCIONES' : 'BUSCAR ORDENES DE COMPRA' }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="10">
          <!-- recepcionOrdenes, array usado para el autoselecion (las disponible) -->
          <v-autocomplete
            v-if="nuevo || origenesFacturaCompra.length == 0"
            v-model="selectedRecepcionesOrdenes"
            :item-text="origen == 'recepcion' ? 'descripcion' : 'nombre'"
            :items="recepcionOrdenes"
            hide-details
            multiple
            validate-on-blur
            outlined
            :deletable-chips="nuevo"
            dense
            chips
            return-object
            @change="controlarCotizacion()"
          ></v-autocomplete>
          <v-autocomplete
            v-else
            v-model="origenesFacturaCompra"
            item-text="nombre"
            :items="origenesFacturaCompra"
            hide-details
            multiple
            validate-on-blur
            outlined
            readonly
            :deletable-chips="nuevo"
            dense
            chips
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="3" sm="3" md="2">
          <v-btn color="info" title="Agregar" @click="agregarAdetallesArt()" :disabled="origenesFacturaCompra.length != 0 || !editar">
            IMPORTAR
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <!-- Componente Modal de todas las Recepciones/Ordenes x proveedor -->
    <ModalObtRecepOrdVue
      v-model="objObtenerTodas.activo"
      :datos="objObtenerTodas"
      @agregarSelected="agregarImportar"
    />
    <!-- Seccíon Artículos -->
    <v-col cols="12" class="pb-0 pt-0" id="articulosImport">
      <v-expansion-panels v-model="panel" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-4 pb-4 py-0 px-4 text-h5" style="cursor: default">
            <div>
              Articulos
              <v-btn
                v-if="editar"
                icon small class="pa-2" 
                title="Buscar Artículo" color="info"
                @click="open_modalAgregarArtFact()"
              > <v-icon small>fas fa-search</v-icon> </v-btn>
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-row class="pt-2" v-if="editar">
              <v-col cols="6" md="2" class="pt-0 pb-0">
                Cód. Artículo
                <v-text-field
                  v-model.trim="filtro.articulo_codigo"
                  type="number"
                  hide-details
                  outlined
                  dense
                  @blur="rellenarCampos(null)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="4" class="pt-0 pb-0">
                Descripción
                <v-text-field
                  v-model.trim="filtro.articulo_nombre"
                  :readonly="true"
                  :filled="true"
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="2" class="pt-0 pb-0">
                Cantidad
                <v-text-field
                  v-model.trim="filtro.cantidad"
                  type="number"
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" md="2" class="pt-0 pb-0">
                Importe
                <TextFieldMoneyVue
                  :precision="4"
                  v-model="filtro.precio"
                  v-bind:properties="{
                    prefix: '$',
                    'hide-details': true,
                    'readonly': true,
                    'filled': true,
                    'validate-on-blur': true
                  }"
                />
              </v-col>
              <v-col cols="3" md="2" class="pt-0 pb-0">
                <v-btn
                  class="mt-4"
                  color="info"
                  type="submit"
                  :disabled="!filtro.articulo_codigo"
                  @click="agregarDetalle(filtro, 0)"
                >  Agregar </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col cols="12" class="pt-0 pb-2">
                <v-data-table
                  class="cebra elevation-2 mt-6"
                  :headers="editar ? headerDetalles : headerDetalles_2"
                  sort-by="articulo_codigo"
                  :items="detallesFacturaCompra"
                  :loading="load"
                  :search="search"
                  item-key="id_id"
                  :footer-props="{'items-per-page-options':[5, 10, 15, 50, 100]}"
                  dense
                >
                  <!-- Busqueda -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-3" no-gutters>
                      <v-col cols="6" sm="3" md="2">
                        <SearchDataTableVue
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Si no hay datos -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para mostrar
                    </v-alert>
                  </template>
                  <template v-slot:[`item.cantidad`]="{item}" v-if="editar">
                    <v-text-field-integer
                      v-model="item.cantidad"
                      v-bind:properties="{
                        type: 'tel',
                        'hide-details': true,
                        outlined: true,
                        dense: true
                      }"
                      @blur="recalcularImporte(item)"
                    />
                  </template>
                  <template v-slot:[`item.precio`]="{item}">
                      <text-field-money-vue
                        v-if="editar"
                        v-model="item.precio"
                        :precision="4"
                        class="d-inline-flex"
                        v-bind:properties="{
                          'prefix': '$',
                          'hide-details': true
                        }"
                        @blur="recalcularImporte(item)"
                      />
                      <div v-else>
                        {{ format_money_round(item.precio,4) }}
                      </div>
                  </template>
                  <!-- Importe = Cantidad*Precio sin iva -->
                  <template v-slot:[`item.importe`]="{item}">
                    {{ format_money_round((parseInt(item.cantidad) * item.precio),4) }}
                  </template> 
                  <template v-slot:[`item.tasa`]="{item}">
                    {{ roundNumber(item.tasa,4) }}
                  </template>
                  <!-- IVA = Importe*Tasa -->
                  <template v-slot:[`item.iva`]="{item}">
                      <text-field-money-vue
                        :precision="4"
                        v-if="editar"
                        v-model="item.iva"
                        class="d-inline-flex"
                        v-bind:properties="{
                          'prefix': '$',
                          'hide-details': true
                        }"
                        @blur="recalcularIva(item)"
                      />
                      <div v-else> {{ format_money_round(item.iva,4) }} </div>
                  </template>
                  <!-- % Impuesto Interno -->
                  <template v-slot:[`item.tasa_impuesto`]="{item}" v-if="editar">
                    <text-field-money-vue
                      v-model=" item.tasa_impuesto"
                      v-if="editar"
                      :precision="4"
                      @blur="recalcularImpuestoInterno(item)"
                      v-bind:properties="{
                        'suffix': '%',
                        'hide-details': true
                      }"
                    />
                    <div v-else>{{ parseFloat(item.tasa_impuesto.toFixed(4)) }}%</div>
                  </template>
                  <!-- Importe Imp Interno -->
                  <template v-slot:[`item.importe_imp_interno`]="{item}">
                    {{ format_money_round(item.importe_imp_interno,4) }}
                  </template>
                  <template v-slot:[`item.accion`]="{ item }"  v-if="editar">
                    <v-icon
                      color="error"
                      title="Eliminar"
                      small
                      @click="eliminarArticulo(item)"
                    >
                      fas fa-times-circle
                    </v-icon>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    
                  </template>
                </v-data-table>
                <v-simple-table class="mt-4 pr-4">
                  <tr class="font-weight-bold ">
                    <th style="font-size: 1em" colspan="4" class="text-right">Cant. total: </th>
                    <th style="font-size: 1em">{{retornarCantidad}}</th>
                    <th style="font-size: 1em" colspan="5" class="text-right">Subtotal: </th>
                    <th style="font-size: 1em" class="text-right">{{ format_money_round(subTotal,2) }}</th>
                    <th v-if="editar"></th>
                  </tr>
                  <tr class="font-weight-bold text-right">
                    <th style="font-size: 1em"   colspan="10"  >IVA 21%</th>
                    <th style="font-size: 1em; width: 20%; " class="text-right">{{ format_money_round(acuIva21,2) }}</th>
                    <th v-if="editar"></th>
                  </tr>
                  <tr class="font-weight-bold text-right">
                    <th style="font-size: 1em"  colspan="10"  >IVA 10,5%</th>
                    <th style="font-size: 1em" class="text-right">{{ format_money_round(acuIva105,2) }}</th>
                    <th v-if="editar"></th>
                  </tr>
                  <tr class="font-weight-bold text-right">
                    <th style="font-size: 1em"  colspan="10">IVA 27%</th>
                    <th style="font-size: 1em" >{{ format_money_round(acuIva27,2) }}</th>
                    <th v-if="editar"></th>
                  </tr>
                  <tr class="font-weight-bold text-right" > 
                    <th style="font-size: 1em"   colspan="10">Imp. Interno:</th>
                    <th style="font-size: 1em">{{ format_money_round(importeImpuestoInternoAcu,2) }}</th>
                    <th v-if="editar"></th>
                  </tr>
                  <tr class="font-weight-bold text-right">
                    <th style="font-size: 1em"   colspan="10" >Ret. Esp.:</th>
                    <th style="font-size: 1em">{{ format_money_round(retornarRetencionesAcu,2) }}</th>
                    <th v-if="editar"></th>
                  </tr>
                  <tr class="font-weight-bold text-right">
                    <th style="font-size: 1em"  colspan="10" >Total: </th>
                    <th style="font-size: 1em">{{ format_money_round(totalTotal,2) }}</th>
                    <th v-if="editar"></th>
                  </tr>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Componente que abre todos los articulos -->
    <ModalElegirArticuloFCVue
      v-model="objAgregarAtrFact.activo"
      :datos="objAgregarAtrFact"
      @agregarNvo="chequearArticulo"
    />
    <!-- Componente para seleccionar articulos que se repiten -->
    <SeleccionarArticuloVue
      :dialogActivo="dialogSelectArticulo"
      :detallesArts="selectArticulos"
      @setearModalSelectArt="setModalSelectArt"
      @seleccionarArt="setArtSeleccionado"
    />
    <!-- Retenciones Especiales -->
    <v-col cols="12" class="pb-0" id="articulosImport">
      <v-expansion-panels v-model="panelR" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-4 pb-4 py-0 px-4 text-h5" style="cursor: default">
            <div>
              Retenciones especiales
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panelR = panelR === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-3">
            <v-row v-if="(editar && !nuevo) || nuevo">
              <v-col cols="12" sm="2" md="2" class="py-0">
                Código Ret. Esp.
                <v-text-field
                  v-model="filtroRetenciones.codigo"
                  outlined
                  hide-details
                  dense
                  :readonly="!editar && !nuevo"
                  @blur="completarRetEspecial(1)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0">
                Retencion
                <v-autocomplete
                  v-model="selectedRetencion"
                  item-text="descripcion"
                  :items="retencionesEspeciales"
                  hide-details
                  outlined
                  dense
                  return-object
                  :disabled="!editar && !nuevo"
                  @change="completarRetEspecial(2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                Tipo Ret. Esp.
                <v-text-field
                  v-model="filtroRetenciones.tipo"
                  outlined
                  hide-details
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                Importe
                <TextFieldMoneyVue
                  :precision="4"
                  v-model="filtroRetenciones.importeRet"
                  @keypress.enter="addRetencion()"
                  v-bind:properties="{
                    prefix: '$',
                    'hide-details': true,
                    'readonly': !editar && !nuevo,
                    'validate-on-blur': true
                  }"
                />
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0 pt-5">
                <v-btn color="primary" title="Agregar Retención" :disabled="!editar && !nuevo" @click="addRetencion()">Agregar</v-btn>
              </v-col>
            </v-row>
            <!-- Tabla de Retenciones Especiales -->
            <v-row>
              <v-col cols="12">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="headerRet"
                  :items="retencionesFacturaCompra"
                  :loading="loadR"
                  dense
                  :search="searchR"
                >
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTableVue
                          v-model="searchR"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:[`item.importe`]="{ item }">
                    {{ format_money_round(item.importe,2) }}
                  </template>
                  <!-- Acciones y modal para confirmar -->
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-btn icon small @click="quitarRetencion(item)" :disabled="!editar && !nuevo"><v-icon small color="error" title="Quitar Retencion">fas fa-times</v-icon></v-btn>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th style="font-size: 1em" class="text-center">Total: {{ format_money_round(retornarRetencionesAcu,2) }}</th>
                      <th></th>
                    </tr>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Pagos -->
    <v-col cols="12" class="pb-0" id="articulosImport">
      <v-expansion-panels v-model="panelP" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-4 pb-4 py-0 px-4 text-h5" style="cursor: default">
            <div>
              Pagos
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panelP = panelP === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-3">
            <v-row v-if="editar || nuevo">
              <v-col cols="12">
                <v-switch
                  v-model="switchFormaPago"
                  class="d-flex align-center py-0 my-0"
                  hide-details
                  dense
                  :label="`Forma de Ingreso: ${switchFormaPago ? 'Importe' : 'Porcentaje'}`"
                ></v-switch>
              </v-col>
            </v-row>
            <!-- Agregar Pago -->
            <v-row class="pt-4" v-if="editar || nuevo">
              <v-col cols="12" sm="4" md="2" class="py-0">
                Fecha
                <FechaPickerVue
                  v-model="filtroPago.fechaPago"
                  hide-details
                  ref="fechaPagoEstimada"
                  :disabled="!editar && !nuevo"
                  @blur="validaFechaPago()"
                />
              </v-col>
              <v-col cols="12" sm="4" md="2" class="py-0">
                Porcentaje
                <v-text-field
                  v-model="filtroPago.porcentajePago"
                  outlined
                  dense
                  type="number"
                  suffix="%"
                  hide-details
                  :readonly="switchFormaPago"
                  :filled="switchFormaPago"
                  @blur="validaPorcentaje()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="py-0">
                Importe
                <TextFieldMoneyVue
                  v-model="filtroPago.importePago"
                  :precision="2"
                  @blur="validaImportePago()"
                  v-bind:properties="{
                    prefix: '$',
                    'hide-details': true,
                    'readonly': !switchFormaPago,
                    'filled': !switchFormaPago,
                    'validate-on-blur': true
                  }"
                />
              </v-col>
              <v-col cols="12" sm="4" md="3" class="py-0">
                Forma de Pago
                <v-autocomplete
                  v-model="filtroPago.formasPago"
                  item-text="forma_pago_nombre"
                  item-value="forma_pago_id"
                  :items="formasPago"
                  hide-details
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="2" class="py-0">
                Tipo de Pago
                <v-autocomplete
                  v-model="filtroPago.tipoPago"
                  item-text="tipo_pago_nombre"
                  item-value="tipo_pago_id"
                  :items="tiposPagos"
                  hide-details
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="py-0 pt-5">
                <v-btn title="Agregar Pago" icon @click="addPago()"><v-icon color="success">far fa-check-circle</v-icon></v-btn>
              </v-col>
            </v-row>
            <!-- Tabla de Pagos -->
            <v-row>
              <v-col cols="12">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="headerPagos"
                  :items="periodosPagoFacturaCompra"
                  :loading="loadP"
                  dense
                  :search="searchP"
                  :disabled="!editar && !nuevo"
                  item-key="id_key"
                >
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTableVue
                          v-model="searchP"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Importes -->
                  <template  v-slot:[`item.importe`]="{ item }">
                    {{ format_money_round(item.importe,4) }}
                  </template>
                  <!-- Porcentajes -->
                  <template v-slot:[`item.porcentaje_pago`]="{ item }">
                    {{ item.porcentaje_pago }}%
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-btn icon small @click="quitarPago(item)" :disabled="!editar && !nuevo" v-if="item.pago == 0"><v-icon small color="error" title="Quitar pago">fas fa-times</v-icon></v-btn>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th></th>
                      <th class="text-center">{{ format_money_round(totalFinalPorcentaje,4) }}%</th>
                      <th></th>
                      <th></th>
                      <th class="text-center">{{ format_money_round(totalFinalPagos,4) }}</th>
                      <th></th>
                    </tr>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12">
      <!-- Botones para guardar o cancelar -->
      <v-row v-if="editar">
        <v-col cols="12" class="d-flex justify-end align-center">
          <v-btn class="mr-6 pt-1" icon  @click="subirArchivo(datosFactura)" v-if="!nuevo" :disabled="!editar">
            <v-icon :color="parseInt(datosFactura.con_archivo) > 0 ? 'success' : 'error'" title="Subir Archivos">
              fas fa-cloud-upload-alt
            </v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            color="secondary"
            @click="cerrar()"
          >
            CANCELAR
          </v-btn>
          <BtnConfirmarVue
            :loading="load"
            :nombre="nuevo ? 'GRABAR' : 'GUARDAR'"
            @action="guardar()"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</div>
</template>

<script>
import moment from 'moment';
import FechaPickerVue from '../../components/util/FechaPicker.vue';
import { format_money, format_money_round, roundNumber ,order_list_by, cadenaVacia } from '../../util/utils';
import TextFieldMoneyVue from '../../components/util/TextFieldMoney.vue';
import ModalObtRecepOrdVue from '../../components/auditoria/ModalObtRecepOrd.vue';
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import BtnConfirmarVue from '../../components/util/BtnConfirmar.vue';
import ModalElegirArticuloFCVue from '../../components/auditoria/ModalElegirArticuloFC.vue';
import SeleccionarArticuloVue from '../../components/generales/SeleccionarArticulo.vue';
import ModalSubirArchivosVue from '../../components/generales/facturasCompra/ModalSubirArchivos.vue';
import router from '../../router'

export default {
  name: 'UnaFacturaCompraAud',
  data(){
    return{
      moment: moment,
      formatMoney: format_money,
      format_money_round: format_money_round, 
      roundNumber: roundNumber,
      editar: false,
      existe: false,
      nuevo: false,
      panel: 0,
      panelR: 0,
      panelP: 0,
      load: false,
      loadR: false,
      loadP: false,
      search: '',
      searchR: '',
      searchP: '',
      dialog: false,
      dialogSelectArticulo: false,
      factura_id: this.$route.params.id, //ID de la url
      permisoEditar: 1,
      //filtro
      selectedProveedor: {},
      selectedTipoFactura: {},
      selectedFactCompraAsoc: {},
      selectedRecepcionesOrdenes: [],
      selectedMoneda: null,
      cotizacion: '',
      cotRef: 'cotRef',
      //init panel 1
      empresas:[],
      proveedores:[],
      sucursales: [],
      multiCuentaProv: [],
      tiposFactura: [],
      facturasCompraAsociada: [],
      condicionesPago:[],
      estadosControl: [],
      monedas: [],
        //
      retencionesEspeciales: [], // panel Retenciones especiales
      tiposPagos: [],// panel pagos
      formasPago: [],// panel pagos
      factura_compra: {}, //cuando ya existe
      // Datos usados en el primer filtro
      datosFactura: {
        "factura_id": this.$route.params.id,
        "fc_asoc": 0,
        "fecha": moment(new Date()).format('DD/MM/YYYY'),
        "fecha_costos": moment(new Date()).format('DD/MM/YYYY'),
        "proveedor_codigo": 0,
        "proveedor_nombre": '',
        "sucursal_codigo": 0,
        "sucursal_nombre": "",
        "estado_nombre": "",
        "estado_codigo": 0,
        "usuario": "",
        "total": 0,
        "if_modifica": null,
        "if_anula": null,
        "emision": null,
        "numero": null,
        "tipo_factura": null, //tipo_letra
        "tipo_factura_codigo": null, //comp_tipo_id
        "con_archivo": null,
        "referencia": null,
        "empresa_codigo": null,
        "empresa_nombre": null,
        "tipo_factura_id": null, //tipo_id
        "tipo_ingreso": null,
        "dias_pago": null,
        "fecha_contable": null,
        "proveedor_cuit": null,
        "multicuenta": null,
        "proveedor_bejerman_nombre": null,
        "proveedor_bejerman_codigo": null,
        "condicion_pago_bejerman": null,
        "estado_control_id": null,
        "estado_control_nombre": null,
        "moneda_id": null,
        "cotizacion": null,
        "new_cotizacion": null,
      },
      fecha_costos_minima: moment(new Date()).subtract(90,'days'),
      ruta_carpeta: '',
      facturaSeleccionada: '',
      dialogSubirArchivo: false,
      datosFacturaCopia:{},
      detallesAux: [], //para cuando haya nueva cotización
      detallesFacturaCompra: [],
      detallesFacturaCompraCopia: [],
      retencionesFacturaCompra:[], //data-table de retenciones
      retencionesFacturaCompraCopia: [],
      periodosPagoFacturaCompra: [], //date-table de pagos
      periodosPagoFacturaCompraCopia: [],
      origenesFacturaCompra:[],
      //
      origen: null,
      todasAsociaciones: [],
      todasOrdCompra:[],
      recepcionOrdenes:[],
      recepcionOrdenesCopia: [],
      objObtenerTodas: {
        activo: false,
        proveedor_codigo: 0,
        proveedor_nombre: '',
        moneda_id: null,
        origen: null,
        agregados: false,
      },
      //Panel de Articulos
      todosArticulos: [],
      selectArticulos: [],
      indexDetalles: 0,
      objAgregarAtrFact:{
        activo: false,
        proveedor_codigo: 0,
        empresa_codigo: -1, 
      },
      filtro:{
        articulo_codigo: null,
        articulo_nombre: null,
        precio: null,
        cantidad: 1,
        articulo_cod_barras:"",
        formula:0,
        gravamen_codigo: null,
        precio: null,
        precio_s_iva: null,
        proveedor_codigo: 0,
        proveedores_articulos_id: 0,
      },
      headerDetalles: [
        { text: 'Código artículo', value: 'articulo_codigo',  },
        { text: 'Nombre', value: 'articulo_nombre'},
        { text: 'Cant.', value: 'cantidad', width: '70' },
        { text: 'Precio', value: 'precio', align: 'right'},        
        { text: 'Importe', value: 'importe', align: 'end'}, 
        { text: 'Tasa', value: 'tasa',  },   
        { text: 'IVA', value: 'iva', align: 'end' },
        { text: '%Imp. Interno', value: 'tasa_impuesto',  align: 'end' },        
        { text: 'Imp. Interno', value: 'importe_imp_interno', align: 'end'},        
        { text: 'Acción', value: 'accion', filtreable: false, align:'center' },
      ],
      headerDetalles_2: [
        { text: 'Código artículo', value: 'articulo_codigo',  },        
        { text: 'Nombre', value: 'articulo_nombre',  },        
        { text: 'Cant.', value: 'cantidad', width: '70' },
        { text: 'Precio', value: 'precio', },
        { text: 'Importe', value: 'importe', align: 'end' },       
        { text: 'Tasa', value: 'tasa',  },         
        { text: 'IVA', value: 'iva', align: 'end'   },
        { text: '%Imp. Interno', value: 'tasa_impuesto',  align: 'end' },        
        { text: 'Imp. Interno', value: 'importe_imp_interno', align: 'end' },
      ],
      precioItemAux: 0,
      id_key: 0,
      itemPrecio: 0,
      //Reglas
      rules:{
        required: value => !!value || 'Campo requerido',
        required_zero: value => {
          if (value == null || value == undefined) {
            return 'Campo requerido'
          } else {
            return true
          }
        }
      },
      //Parametros globales
      subTotal: 0,
      acuIva21: 0,
      acuIva27: 0,
      acuIva105: 0,
      importeImpuestoInternoAcu: 0,
      retEspAcu: 0,
      totalNeto: 0,
      totalTotal: 0,
      //Retenciones
      selectedRetencion:{},
      filtroRetenciones: {
        importeRet: null,
        articulo:null,
        codigo: null,
        computo:null,
        descripcion:null,
        id:null,
        impuesto:null,
        inhabilitado:null,
        signo:null,
        tipo:null,
      },
      headerRet: [
        { text: 'Descripcion', align: 'center', value: 'descripcion' },
        { text: 'Codigo', align: 'center', value: 'codigo' },
        { text: 'Tipo', align: 'center', value: 'tipo' },
        { text: 'Importe', align: 'center', value: 'importe'},
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      //Pagos
      switchFormaPago: false,
      filtroPago:{
        fechaPago: moment(new Date()).format('DD/MM/YYYY'),
        porcentajePago: null,
        importePago: null,
        tipoPago: null,
        formasPago: null,
      },
      totalFinalPorcentaje: 0,
      totalFinalPagos: 0,
      headerPagos: [
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Porcentaje', align: 'center', value: 'porcentaje'},
        { text: 'Forma de Pago', align: 'center', value: 'compra_fp_nombre' },
        { text: 'Tipo de Pago', align: 'center', value: 'tipo_pago_nombre' },
        { text: 'Monto', align: 'center', value: 'importe'},
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      //Reglas
      rules:{
        required: value => !!value || 'Campo requerido',
        required_zero: value => {
          if (value == null || value == undefined) {
            return 'Campo requerido'
          } else {
            return true
          }
        }
      }
    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      let param;
      //si es nueva
      if(!this.factura_id){
        this.nuevo = true;
        this.existe = true; 
        this.editar = true; //siempre en true para Nuevas
        param = 0;
      }//si ya existe
      else{
        this.nuevo  = false;
        this.existe = true;
        param       = this.factura_id;
      }
      this.empresas             = JSON.parse(localStorage.getItem('empresas'))
      this.$store.state.loading = true;
      const res                 = await this.$store.dispatch('facturasCompra/iniciarFormulario',param)
      this.$store.state.loading = false;
      if(res.resultado != 1){
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', });
      }
      
      this.sucursales             = res.sucursales;
      this.condicionesPago        = res.condiciones;
      this.estadosControl         = res.estados;
      this.monedas                = res.monedas;
      this.tiposFactura           = res.tipos_fc;
      this.tiposPagos             = res.tipos_pagos;
      this.retencionesEspeciales  = res.retenciones_especiales;
      this.formasPago             = res.formas_pago;
      this.factura_compra         = res.factura_compra;
      
      if(Object.keys(this.factura_compra).length > 0){
        this.datosFactura                 = this.factura_compra.encabezado[0]; //guarda la info de lo que viene de la API
        this.datosFactura.referencia      = this.datosFactura.referencia? this.datosFactura.referencia : '';
        this.datosFactura.new_cotizacion  = null;
        this.fecha_costos_minima          = moment(this.datosFactura.fecha_costos).subtract(90,'days');
        this.datosFactura.fecha_costos    = moment(this.datosFactura.fecha_costos).format('DD/MM/YYYY');
        this.datosFacturaCopia            = JSON.parse(JSON.stringify(this.datosFactura));
        
        this.detallesFacturaCompra = this.factura_compra.detalles;
        for(let i in this.detallesFacturaCompra){
          //this.detallesFacturaCompra[i].tasa = this.detallesFacturaCompra[i].formula;
          this.detallesFacturaCompra[i].id_id   = this.indexDetalles;
          this.detallesFacturaCompra[i].formula = this.detallesFacturaCompra[i].tasa
          this.indexDetalles++;
        }
        await this.getTodosArticulos();
        this.detallesFacturaCompraCopia     = JSON.parse(JSON.stringify(this.detallesFacturaCompra));

        this.retencionesFacturaCompra       = this.factura_compra.retenciones_especiales;
        this.retencionesFacturaCompraCopia  = JSON.parse(JSON.stringify(this.retencionesFacturaCompra));

        this.periodosPagoFacturaCompra      = this.factura_compra.periodos_pago;
        this.periodosPagoFacturaCompraCopia = JSON.parse(JSON.stringify(this.periodosPagoFacturaCompra));

        this.origenesFacturaCompra          = this.factura_compra.origenes;

        //tipoComprobante
        this.selectedTipoFactura            = (this.tiposFactura.filter(elem => elem.tipo_id == 1))[0];
        //proveedor
        this.selectedProveedor.multicuenta      = this.datosFactura.multicuenta;
        this.selectedProveedor.proveedor_codigo = this.datosFactura.proveedor_codigo;
        this.selectedProveedor.proveedor_cuit   = this.datosFactura.proveedor_cuit;
        this.selectedProveedor.proveedor_nombre = this.datosFactura.proveedor_nombre;
        this.proveedores.push(this.selectedProveedor);

        //Calula totales de periodos de pago
        this.retornarTotalPorcentajes();
        this.retornarTotalesPagos();
      }
      this.llamarAcalcular();
    },
    subirArchivo(item){
      this.facturaSeleccionada = ''
      // indico la ruta donde se van a guardar los archivos
      this.ruta_carpeta = '/facturas_compras/' + item.factura_id
      this.facturaSeleccionada = item.factura_id.toString()
      // abro el modal
      this.dialogSubirArchivo = true
    },
    closeModalSubirArchivo(){
      this.dialogSubirArchivo = false;
    },
    async getProveedores(){
      this.selectedProveedor  = {};
      this.multiCuentaProv    = [];
      this.datosFactura.tipo_factura_id = null;
      // control de empresa
      if (this.datosFactura.empresa_codigo){
        this.$store.state.loading = true
        await this.getTodosArticulos();
        this.$store.dispatch('facturasCompra/get_proveedores', {id_empresa: this.datosFactura.empresa_codigo})
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            this.proveedores = response.proveedores
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener los proveedores: ' + error.message,
            color: 'error',
          })
          return
        })
      }
    },
    async verifyMultiCuenta(){
      if(this.nuevo){
        this.multiCuentaProv = [];
        this.datosFactura.proveedor_bejerman_codigo = null;
      }
      if (this.selectedProveedor == null || this.selectedProveedor == undefined){
        this.selectedProveedor = {}
      }
      //if(this.selectedProveedor == null || Object.keys(this.selectedProveedor).length == 0){
        this.selectedTipoFactura              = {};
        this.datosFactura.moneda_id           = null;
        this.datosFactura.tipo_factura        = null;
        this.datosFactura.tipo_factura_codigo = null;
        this.datosFactura.tipo_factura_id     = null;
        this.origen                           = null;
        this.detallesFacturaCompra            = [];
        this.periodosPagoFacturaCompra        = [];
        this.retencionesFacturaCompra         = [];
        this.subTotal                         = 0;
        this.acuIva21                         = 0;
        this.acuIva27                         = 0;
        this.acuIva105                        = 0;
        this.importeImpuestoInternoAcu        = 0;
        this.retEspAcu                        = 0;
        this.totalNeto                        = 0;
        this.totalTotal                       = 0;
        this.totalFinalPagos                  = 0;
        this.totalFinalPorcentaje             = 0;
      //}
      this.datosFactura.proveedor_codigo = this.selectedProveedor.proveedor_codigo;
      this.datosFactura.proveedor_nombre = this.selectedProveedor.proveedor_nombre;
      await this.getTodosArticulos();
      if (this.selectedProveedor.multicuenta == 1 && this.nuevo){ this.getMultiCuentaProveedor()}
    },
    async getMultiCuentaProveedor(){
      this.$store.state.loading = true
      let multiPeticion = await this.$store.dispatch('facturasCompra/get_multi_proveedor', {proveedor_codigo: this.selectedProveedor.proveedor_codigo, empresa_codigo: this.datosFactura.empresa_codigo})
      this.$store.state.loading = false
      if (multiPeticion.resultado == 1) this.multiCuentaProv = multiPeticion.proveedores
      else{
        this.$store.dispatch('show_snackbar', {
          text: multiPeticion.msj,
          color: 'error',
        })
      }
    },
    async getTodosArticulos(){
      if(this.datosFactura.empresa_codigo && this.datosFactura.proveedor_codigo){
        const res = await this.$store.dispatch('facturasCompra/obtenerArticulos',{
          empresa_codigo: this.datosFactura.empresa_codigo,
          proveedor_codigo: this.datosFactura.proveedor_codigo,
          todos: 0,
        })
        if(res.resultado == 1){
          this.todosArticulos = res.articulos
          for(let i in this.todosArticulos){
            this.todosArticulos[i].precio = this.todosArticulos[i].precio_s_iva;
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error',
          })
        }
      }
    },
    setEncImpTotal(){
      //this.datosFactura.total = parseFloat(this.datosFactura.total.toFixed(4))
      this.datosFactura.total = roundNumber(this.datosFactura.total,4);
    },
    verificarCotizacion(){
      if(this.datosFactura.moneda_id){
        this.origen = null;
        this.datosFactura.cotizacion = null;
      }
    },
    async getFactComprobante(){
      this.origen = null;
      this.facturasCompraAsociada = [];
      this.selectedFactCompraAsoc = {};
      if (this.nuevo){
        this.datosFactura.moneda_id = null;
        this.datosFactura.cotizacion = null;
        this.datosFactura.new_cotizacion = null;
      }
      this.datosFactura.factura_id = null;
      let aux = this.tiposFactura.filter(elem => elem.tipo_id == this.datosFactura.tipo_factura_id)
      this.selectedTipoFactura = aux[0];
      if(aux.length>0){
        this.datosFactura.tipo_factura = this.selectedTipoFactura.tipo_letra;
        this.datosFactura.tipo_factura_codigo = this.selectedTipoFactura.comp_tipo_id;
        this.datosFactura.tipo_factura_id = this.selectedTipoFactura.tipo_id;
      }

      //return 
      if(this.datosFactura.empresa_codigo && Object.keys(this.selectedProveedor).length > 0){
        if(this.selectedTipoFactura != null && Object.keys(this.selectedTipoFactura).length > 0){
          if(this.selectedTipoFactura.comp_tipo_id == 8 || this.selectedTipoFactura.comp_tipo_id == 9){
            this.$store.state.loading = true
            let peticion = await this.$store.dispatch('facturasCompra/obtenerFacturasCompraProveedor', {proveedor_codigo: this.selectedProveedor.proveedor_codigo, empresa_codigo: this.datosFactura.empresa_codigo, tipo: this.selectedTipoFactura.tipo_letra})
            this.$store.state.loading = false
            if (peticion.resultado == 1) { 
              this.facturasCompraAsociada = peticion.facturas 
            }//
            else{
              this.$store.dispatch('show_snackbar', {
                text: multiPeticion.msj,
                color: 'error',
              })
            }
          }
        }else{
          this.datosFactura.moneda_id = null;
          this.datosFactura.factura_id = null;
          this.datosFactura.cotizacion = null;
        }
      }else{
        this.datosFactura.tipo_factura = null;
        this.datosFactura.tipo_factura_codigo = null;
        this.datosFactura.tipo_factura_id = null;
        this.selectedTipoFactura = {};
        return this.$store.dispatch('show_snackbar', {
          text: 'En necesario que seleccione Empresa y Proveedor.',
          color: 'orange',
        })
      }
    },
    async getFacturaAsoc(){
      if(this.selectedFactCompraAsoc != null || this.selectedFactCompraAsoc != undefined/* || Object.keys(this.selectedFactCompraAsoc).length != 0*/){
        this.datosFactura.factura_id = this.selectedFactCompraAsoc.factura_id;
        this.datosFactura.fc_asoc = this.selectedFactCompraAsoc.factura_id
        this.datosFactura.moneda_id = this.selectedFactCompraAsoc.moneda_id;
        this.datosFactura.cotizacion = this.selectedFactCompraAsoc.cotizacion;
      }else{
        this.datosFactura.moneda_id = null;
        this.datosFactura.cotizacion = null;
        this.datosFactura.new_cotizacion = null;
        this.datosFactura.fc_asoc = 0;
      }
    },
    async getArtNewCot(){
      if(this.datosFactura.moneda_id == 2){
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('facturasCompra/obtenerDetalleFacturasCompraAsociada', this.selectedFactCompraAsoc.factura_id);
        this.$store.state.loading = false;
        if(res.resultado == 1){
          if(this.datosFactura.new_cotizacion){
            let cot = 0;
            if(this.datosFactura.cotizacion && this.datosFactura.cotizacion != 0){
              //cot = parseFloat(this.datosFactura.cotizacion.toFixed(4));
              cot = roundNumber(this.datosFactura.cotizacion,6)
            }
            //let newCot = parseFloat(this.datosFactura.new_cotizacion.toFixed(4));
            let newCot = roundNumber(this.datosFactura.new_cotizacion,6);

            let diferencia = Math.abs(cot-newCot);
            let nuevoCoef = roundNumber((diferencia/newCot),6);

            this.detallesAux = res.detalles;
            let obj;
            for(let i in this.detallesAux){
              obj = {};
              let det = this.detallesAux[i];
              det.precio = roundNumber(roundNumber(det.precio,6) * nuevoCoef,6);
              det.importe = parseInt(det.cantidad) * det.precio;
              det.neto = det.importe // agrego ese
              det.iva = det.importe * det.formula;
              if(det.impuesto_interno != 0){
                det.importe_imp_interno = det.importe * roundNumber((roundNumber(det.impuesto_interno,6)/100),6);
              }
              det.importe_imp_interno = roundNumber(det.importe_imp_interno,6);
              obj = {
                articulo_cod_barras: det.codigo_barras,
                articulo_codigo: det.articulo_codigo,
                articulo_nombre: det.nombre,
                cantidad: det.cantidad,
                formula: det.formula,
                gravamen_codigo: det.gravamen_codigo,
                id: 0,
                id_id: this.indexDetalles,
                importe: det.importe,
                importe_imp_interno: det.importe_imp_interno,
                impuesto_interno: det.impuesto_interno,
                iva: det.iva,
                neto: det.neto,
                precio: det.precio,
                tasa: det.formula,
                tasa_impuesto: det.impuesto_interno,
              }
              this.detallesFacturaCompra.push(obj);
              this.indexDetalles++;
            }
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error',
          })
          return
        }
      }
    },
    async getOrigen(){
      this.selectedRecepcionesOrdenes = [];
      let path;
      let obj;
      let band = 0;
      if(this.origen && (this.nueva || (!this.nueva && this.editar))){
        if(this.origen == 'recepcion'){
          path = 'facturasCompra/obternerRecepcioneXproveedor'
          obj = {proveedor_codigo: this.selectedProveedor.proveedor_codigo, opcion: 1}
        }else{
          if(!this.datosFactura.moneda_id){
            this.origen = '';
            return this.$store.dispatch('show_snackbar', {
              text: 'En necesario que seleccione Moneda',
              color: 'orange',
            })
          }
          band = 1;
          path = 'facturasCompra/obtenerOrdenesDeCompraDisponibles'
          obj = {proveedor_codigo: this.selectedProveedor.proveedor_codigo, opcion: 1, moneda_id: this.datosFactura.moneda_id}
        }
        if(this.origen == 'recepcion' || ( this.origen == 'orden-de-compra' && band == 1)){
          this.$store.state.loading = true
          let peticion = await this.$store.dispatch(path,obj)
          this.$store.state.loading = false
          if (peticion.resultado == 1) { 
            this.recepcionOrdenes = this.origen == 'recepcion' ? peticion.recepciones : peticion.ordenes; 
            this.recepcionOrdenesCopia = JSON.parse(JSON.stringify(this.recepcionOrdenes));
          }//
          else{
            this.$store.dispatch('show_snackbar', {
              text: peticion.message,
              color: 'error',
            })
          }
        }
      }
    },
    //Boton Azul agregar (al lado del autocomplete)
    async agregarAdetallesArt(){
      this.detallesFacturaCompra = [];
      if(this.selectedRecepcionesOrdenes.length > 0){
        if(this.datosFactura.moneda_id == 2 && this.origen != 'recepcion'){
          let may = this.selectedRecepcionesOrdenes[0].cotizacion;
          this.selectedRecepcionesOrdenes.forEach(elem => {
            if(elem.cotizacion > may){
              may = elem.cotizacion
            }
          });
          this.datosFactura.cotizacion = roundNumber(may,2)
        }
        let path;
        let obj;
        let band = 0;
        if(this.origen == 'recepcion'){
          path = 'facturasCompra/obtenerDetallesRecepciones';
          obj = { recepciones : this.selectedRecepcionesOrdenes  }
        }else{
          path = 'facturasCompra/obtenerDetallesOrdenesCompra';
          obj = {
            sucursal_codigo : !this.datosFactura.sucursal_codigo? 0 : this.datosFactura.sucursal_codigo,
            ordenes: this.selectedRecepcionesOrdenes
          }
        }
        this.$store.state.loading = true
        let peticion = await this.$store.dispatch(path,obj)
        this.$store.state.loading = false
        if (peticion.resultado == 1) {
          //carga los pagos si es una sola selección
          /*if(this.selectedRecepcionesOrdenes.length == 1){
            this.periodosPagoFacturaCompra = [];
            this.periodosPagoFacturaCompra = peticion.pagos_oc;
            let n = this.periodosPagoFacturaCompra.length;
            console.log("this.periodosPagoFacturaCompra: ", this.periodosPagoFacturaCompra);
            console.log("this.periodosPagoFacturaCompra.length: ", this.periodosPagoFacturaCompra.length);
            console.log("n: ", n);
            if(n == 1 && this.periodosPagoFacturaCompra[0].tipo_factura_id == 0){
              this.periodosPagoFacturaCompra[0].tipo_pago_id = this.tiposPagos[0].tipo_pago_id;
              this.periodosPagoFacturaCompra[0].tipo_pago_nombre = this.tiposPagos[0].tipo_pago_nombre;
            }else{
              let i = 0;
              while(i < n){
                if(this.periodosPagoFacturaCompra[i].tipo_pago_id == 0){
                  this.periodosPagoFacturaCompra[i].tipo_pago_id = this.tiposPagos[0].tipo_pago_id;
                  this.periodosPagoFacturaCompra[i].tipo_pago_nombre = this.tiposPagos[0].tipo_pago_nombre;
                }
                i++;
              }
            }
          }else this.periodosPagoFacturaCompra = [];*/
          
          //va a la sección de los articulos
          this.detallesFacturaCompra = peticion.detalles;
          for(let i in this.detallesFacturaCompra){
            this.detallesFacturaCompra[i].tasa = this.detallesFacturaCompra[i].formula;
            this.detallesFacturaCompra[i].id_id = this.indexDetalles;
            this.detallesFacturaCompra[i].importe = parseFloat(this.detallesFacturaCompra[i].cantidad) * parseFloat(this.detallesFacturaCompra[i].precio)
            this.detallesFacturaCompra[i].neto = this.detallesFacturaCompra[i].importe;
            this.detallesFacturaCompra[i].impuesto_interno = 0;
            this.detallesFacturaCompra[i].tasa_impuesto = 0;
            this.detallesFacturaCompra[i].iva = parseFloat(this.detallesFacturaCompra[i].importe * this.detallesFacturaCompra[i].tasa)
            this.detallesFacturaCompra[i].articulo_cod_barras = "";
            this.indexDetalles++;
          }
          this.detallesFacturaCompraCopia = JSON.parse(JSON.stringify(this.detallesFacturaCompra));
          this.$vuetify.goTo('#articulosImport')
          this.llamarAcalcular();
        }
        else{
          this.$store.dispatch('show_snackbar', {
            text: multiPeticion.msj,
            color: 'error',
          })
        }
      }else{
        let cad  = ''
        if(this.origen != 'recepcion'){
          cad = 'orden de compra'
        }else cad = 'recepción'
        this.$store.dispatch('show_snackbar', {
          text: 'Se requiere seleccionar al menos una '+cad,
          color: 'orange',
        })
      }
    },
    //prepara y abre el modal
    abrirModalTodas(){
      this.objObtenerTodas.origen = this.origen;
      this.objObtenerTodas.proveedor_nombre = this.datosFactura.proveedor_nombre;
      this.objObtenerTodas.proveedor_codigo = this.datosFactura.proveedor_codigo;
      if(this.origen != 'recepcion'){
        this.objObtenerTodas.moneda_id = this.datosFactura.moneda_id
        this.objObtenerTodas.agregados = this.selectedRecepcionesOrdenes.length > 0 ? true : false
      }
      this.objObtenerTodas.activo = true
    },
    controlarCotizacion(){
      if(this.datosFactura.moneda_id == 2){
        if(this.selectedRecepcionesOrdenes.length != 0){
          let may = this.selectedRecepcionesOrdenes[0].cotizacion;
          this.selectedRecepcionesOrdenes.forEach(elem => {
            if(elem.cotizacion > may){
              may = elem.cotizacion
            }
          });
          this.datosFactura.cotizacion = roundNumber(may,2)
        }else this.datosFactura.cotizacion = null;
      }
    },
    //agrega las recepcion/ordenes selecionadas al array selectedRecepcionesOrdenes para los detalles de Articulos
    agregarImportar(selectedRecOrd){
      //hace el salto a la seccion de Articulos
      let param;
      if(this.origen == 'recepcion'){
        param = 'recepcion_id'
      }else{
        param = 'orden_id'
      }
      selectedRecOrd.forEach(elem => {
        let index = this.selectedRecepcionesOrdenes.map(e => e[param]).indexOf(elem[param]);
        if(index==-1){
          this.recepcionOrdenes.push(elem)
          this.selectedRecepcionesOrdenes.push(elem);
        }
      });
      if(this.origen != 'recepcion'){
        //controla las que se esten por importar
        let i = 0;
        let n = this.selectedRecepcionesOrdenes.length
        let band = false;
        while(i < n && !band){
          if(this.selectedRecepcionesOrdenes[i].moneda_id != this.datosFactura.moneda_id){
            band = true;
          }
          i++;
        }
        if(band == true){
          this.datosFactura.moneda_id = this.selectedRecepcionesOrdenes[0].moneda_id;
        }
        if(this.datosFactura.moneda_id == 2){
          let may = this.selectedRecepcionesOrdenes[0].cotizacion;
          this.selectedRecepcionesOrdenes.forEach(elem => {
            if(elem.cotizacion > may){
              may = elem.cotizacion
            }
          });
          this.datosFactura.cotizacion = roundNumber(may,2)
        }
      }
    },
    //Modal que se abre al abrir la lupa azul
    open_modalAgregarArtFact(){
      if(!this.datosFactura.empresa_codigo || !this.datosFactura.proveedor_codigo){
        return this.$store.dispatch('show_snackbar', {
          text: 'Se requiere ingresar Empresa y Proveedor.',
          color: 'orange'
        })
      }
      this.objAgregarAtrFact.activo = true; 
      this.objAgregarAtrFact.proveedor_codigo = this.datosFactura.proveedor_codigo;
      this.objAgregarAtrFact.empresa_codigo = this.datosFactura.empresa_codigo;
    },
    setModalSelectArt(value){
      this.dialogSelectArticulo = value
    },
    //Es el que agrega si es repetido osea distinto nro de serie
    setArtSeleccionado(param){
      let codigo_barras = param.codigo_barras;
      let codigo_articulo = param.articulo_codigo;
      let aux = this.detallesFacturaCompra.filter(elem => elem.articulo_cod_barras == codigo_barras && elem.articulo_codigo == codigo_articulo);
      if(aux.length == 0){
        param.id = 0;
        param.tasa = parseFloat(param.formula);
        param.id_id = this.indexDetalles;
        param.importe_imp_interno = 0;
        param.tasa_impuesto = 0;
        param.importe = /*roundNumber((*/parseInt(param.cantidad )* (parseFloat(param.precio))//),4); //agrego campo importe
        param.neto = param.importe;
        param.iva = parseFloat(param.importe * param.tasa);
        param.impuesto_interno = 0; //%
        param.articulo_cod_barras = param.codigo_barras;
        this.indexDetalles++;
        this.detallesFacturaCompra.push(param);
        this.llamarAcalcular();
        //this.detallesFacturaCompraCopia = JSON.parse(JSON.stringify(this.detallesFacturaCompra));
        this.$store.dispatch('show_snackbar', {
          text: 'Artículos agregados correctamente a los detalles.',
          color: 'success',
        })
      }else{
        this.$swal.fire({icon: 'error', title: 'Artículo ya existe', text: `Artículo ${param.articulo_codigo} ya se encuentra agregado`});
      }
      this.limpiarFiltro();
    },
    rellenarCampos(){
      if(!this.datosFactura.empresa_codigo || !this.datosFactura.proveedor_codigo){
        this.limpiarFiltro();
        return this.$store.dispatch('show_snackbar', {
          text: 'Se requiere ingresar Empresa y Proveedor.',
          color: 'orange'
        })
      }
      //si no existe abre el modal
      if(this.filtro.articulo_codigo){
        let bus = this.todosArticulos.filter(elem => elem.articulo_codigo == this.filtro.articulo_codigo);
        if(bus.length == 1){ //completa campos
          this.filtro = JSON.parse(JSON.stringify(bus[0]));
          return
        }
        if(bus.length > 1){
          this.selectArticulos = bus.map(elem => ({ 
            articulo_codigo: elem.articulo_codigo,
            codigo_barras: elem.articulo_cod_barras,
            codigo_ean: '',
            articulo_nombre: elem.articulo_nombre,
            cantidad: elem.cantidad,
            formula: elem.formula,
            gravamen_codigo: elem.gravamen_codigo,
            precio: elem.precio,
            precio_s_iva: elem.precio_s_iva,
            proveedor_codigo: elem.proveedor_codigo,
            proveedores_articulos_id: elem.proveedores_articulos_id
          }));
          this.dialogSelectArticulo = true;
          return
        }
      }
      this.limpiarFiltro();
      this.objAgregarAtrFact.proveedor_codigo = this.datosFactura.proveedor_codigo;
      this.objAgregarAtrFact.empresa_codigo = this.datosFactura.empresa_codigo;
      this.objAgregarAtrFact.activo = true;
    },
    limpiarFiltro(){
      this.filtro = {
        articulo_codigo: null,
        articulo_nombre: null,
        precio: null,
        cantidad: 1,
        articulo_cod_barras:"",
        formula:0,
        gravamen_codigo: null,
        precio: null,
        precio_s_iva: null,
        proveedor_codigo: 0,
        proveedores_articulos_id: 0,
      }
    },
    retornarSubtotalImporte(){
      let acu = 0;
      this.detallesFacturaCompra.forEach(elem => acu += parseFloat(elem.importe));
      this.subTotal = acu; //roundNumber(acu,4);  
      this.totalNeto = acu; //roundNumber(acu,4);
    },
    retornarAcu21(){
      let acu = 0;
      this.detallesFacturaCompra.forEach(elem => {
        if(elem.gravamen_codigo == 1){
          acu += parseFloat(elem.iva)
        }
      });
      //acu = roundNumber(acu,4);//parseFloat(acu.toFixed(4));
      this.acuIva21 = acu;
    },
    retornarAcu105(){
      let acu = 0;
      this.detallesFacturaCompra.forEach(elem => {
        if(elem.gravamen_codigo == 2){
          acu += parseFloat(elem.iva)
        }
      });
      //acu = roundNumber(acu,4)//parseFloat(acu.toFixed(4));
      this.acuIva105 = acu;

    },
    retornarAcu27(){
      let acu = 0;
      this.detallesFacturaCompra.forEach(elem => {
        if(elem.gravamen_codigo == 4){
          acu += parseFloat(elem.iva)
        }
      });
      //acu = roundNumber(acu,2);//parseFloat(acu.toFixed(4));
      this.acuIva27 = acu;
    },
    retornarImporteImpuestoInterno(){
      let acu = 0;
      this.detallesFacturaCompra.forEach(elem => acu += parseFloat((elem.importe * elem.tasa_impuesto)/100));
      //acu = roundNumber(acu,4);//parseFloat(acu.toFixed(4));
      this.importeImpuestoInternoAcu = acu; //roundNumber(acu,4);
    },
    retornarTotal(){
      this.totalTotal = this.subTotal + this.acuIva21 + this.acuIva27 + this.acuIva105 + this.importeImpuestoInternoAcu + this.retEspAcu;// + this.totalTotal;
      this.datosFactura.total = parseFloat(this.totalTotal);
      this.totalTotal = parseFloat(this.totalTotal) //parseFloat(this.totalTotal.toFixed(4));
      /*if(this.nuevo) {*///}
    },
    retornarTotalPorcentajes(){
      let acu = 0;
      this.periodosPagoFacturaCompra.forEach(elem => {
        acu += elem.porcentaje
      });
      this.totalFinalPorcentaje = roundNumber(acu,2);
    },
    retornarTotalesPagos(){
      let acu = 0;
      this.periodosPagoFacturaCompra.forEach(elem => {
        acu += elem.importe
      });
      this.totalFinalPagos = roundNumber(acu,2);
    },
    llamarAcalcular(){
      this.retornarSubtotalImporte();
      this.retornarAcu21();
      this.retornarAcu105();
      this.retornarAcu27();
      this.retornarImporteImpuestoInterno();
      this.retornarTotal();
      this.retornarTotalPorcentajes();
      this.retornarTotalesPagos();
    },
    //Blur de los text fields data-table-detalles
    recalcularImporte(item){
      let pos = this.detallesFacturaCompra.map(elem => elem.id_id).indexOf(item.id_id);
      if(pos != -1){
        this.detallesFacturaCompra[pos].importe = parseInt(item.cantidad) * parseFloat(item.precio);
        //this.detallesFacturaCompra[pos].importe = roundNumber(this.detallesFacturaCompra[pos].importe,4)
        
        this.detallesFacturaCompra[pos].iva = parseFloat(this.detallesFacturaCompra[pos].importe) * parseFloat(this.detallesFacturaCompra[pos].tasa);
        //this.detallesFacturaCompra[pos].iva = roundNumber(this.detallesFacturaCompra[pos].iva,4)
        
        this.detallesFacturaCompra[pos].neto = this.detallesFacturaCompra[pos].importe;
      }else{
      }
      this.llamarAcalcular();
    },
    recalcularIva(item){
      this.llamarAcalcular();
    },
    recalcularImpuestoInterno(item){
      let pos = this.detallesFacturaCompra.map(elem => elem.id_id).indexOf(item.id_id);
      if(pos != -1){
        this.detallesFacturaCompra[pos].tasa_impuesto = item.tasa_impuesto;
        this.detallesFacturaCompra[pos].importe_imp_interno = parseFloat(item.importe * item.tasa_impuesto)/100;//roundNumber((parseFloat(item.importe * item.tasa_impuesto)/100),4) //parseFloat(((item.importe * item.tasa_impuesto)/100).toFixed(4));
        //this.detallesFacturaCompra[pos].importe_imp_interno = roundNumber(this.detallesFacturaCompra[pos].importe_imp_interno,4)
      }else{
      }
      this.llamarAcalcular();
    },
    //cuando se le da a la lupa, controla si ese artículo tiene más de un modelo 
    chequearArticulo(param, band){
      let aux = this.todosArticulos.filter(elem => elem.articulo_codigo == param.articulo_codigo)
      if(aux.length <= 1){
        aux[0].cantidad = param.cantidad
        this.agregarDetalle(aux[0], band);
      }else{
        this.objAgregarAtrFact.activo = false;
        this.selectArticulos = aux.map(elem => ({ 
          articulo_codigo: elem.articulo_codigo,
          codigo_barras: elem.articulo_cod_barras,
          codigo_ean: '',
          articulo_nombre: elem.articulo_nombre,
          cantidad: parseInt(elem.cantidad),
          formula: parseFloat(elem.formula),
          gravamen_codigo: elem.gravamen_codigo,
          precio: parseFloat(elem.precio),
          precio_s_iva: elem.precio_s_iva,
          proveedor_codigo: elem.proveedor_codigo,
          proveedores_articulos_id: elem.proveedores_articulos_id
        }));
        this.dialogSelectArticulo = true;
      }
    },
    //agrega un articulo nuevo a los detalles, se pasa this.filtro
    //agregar el articulo a DetallesArticulos, es el que viene del emit(modal), param == item
    agregarDetalle(param, band){
      //controla, si no está lo agrega si está mensaje
      if((this.detallesFacturaCompra.filter(elem => elem.articulo_codigo == param.articulo_codigo)).length == 0 ){
        param.id = 0;
        param.tasa = param.formula;
        param.id_id = this.indexDetalles;
        param.importe_imp_interno = 0;
        param.tasa_impuesto = 0;
        param.importe = parseInt(param.cantidad) * parseFloat(param.precio); //agrego campo importe
        param.impuesto_interno = 0;
        param.iva = parseFloat(param.importe * param.tasa)
        param.articulo_cod_barras = "";
        param.neto = param.importe;
        this.indexDetalles++;
        this.detallesFacturaCompra.push(param);
        this.llamarAcalcular();
        if(band == 1){
          this.$store.dispatch('show_snackbar', {
            text: 'Artículos agregados correctamente a los detalles.',
            color: 'success',
          })
        }
      }else{
        this.$swal.fire({icon: 'error', title: 'Artículo ya existe', text: `Artículo ${param.articulo_codigo} ya se encuentra agregado`});
      }
      this.limpiarFiltro();
    },
    //accion del datatable de articulos
    eliminarArticulo(item){
      this.detallesFacturaCompra = this.detallesFacturaCompra.filter(elem => elem.id_id != item.id_id);
      this.llamarAcalcular();
    },
    //rellena campos del filtro
    completarRetEspecial(opcion){
      if (opcion == 2){
        if (this.selectedRetencion != null && typeof this.selectedRetencion == 'object' && Object.keys(this.selectedRetencion).length > 0){
          /*this.filtroRetenciones.codigo = this.selectedRetencion.codigo
          this.filtroRetenciones.tipoRet = this.selectedRetencion.tipo*/
          this.filtroRetenciones = JSON.parse(JSON.stringify(this.selectedRetencion))
        }
      }else{
        if(this.filtroRetenciones.codigo){
          this.filtroRetenciones.codigo = this.filtroRetenciones.codigo.toUpperCase()
          let unaRetencion = this.retencionesEspeciales.filter(element => element.codigo.toUpperCase() == this.filtroRetenciones.codigo)
          if(unaRetencion.length == 0){
            this.$store.dispatch('show_snackbar', {
              text: 'No se reconoce el código de retención ingresado.',
              color: 'info',
            })
            this.limpiarRetenciones();
            return
          }          
          if(unaRetencion.length >= 1){
            this.selectedRetencion = unaRetencion[0]
            //this.filtroRetenciones.tipo = this.selectedRetencion.tipo;
            this.filtroRetenciones = JSON.parse(JSON.stringify(this.selectedRetencion))
            this.filtroRetenciones.importeRet = null;
          }
        }else{
          this.limpiarRetenciones();
        }
      }
    },
    limpiarRetenciones(){
      this.selectedRetencion = {}
      this.filtroRetenciones = {
        importeRet: null,
        articulo:null,
        codigo: null,
        computo:null,
        descripcion:null,
        id:null,
        impuesto:null,
        inhabilitado:null,
        signo:null,
        tipo:null,
      }
    },
    addRetencion(){
      // controlar los campos vacios
      if (!this.filtroRetenciones.codigo|| Object.keys(this.selectedRetencion).length == 0 || !this.filtroRetenciones.tipo || !this.filtroRetenciones.importeRet){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe completar todos los campos de la retención para agregar.',
          color: 'warning',
        })
        return
      }
      // control de repetidos
      let aux = this.retencionesFacturaCompra.filter(elem => elem.id == this.filtroRetenciones.id);
      if(aux.length == 0){
          this.retencionesFacturaCompra.push({
          descripcion: this.selectedRetencion.descripcion,
          codigo: this.filtroRetenciones.codigo,
          tipo: this.selectedRetencion.tipo,
          importe: parseFloat(this.filtroRetenciones.importeRet),//parseFloat(this.filtroRetenciones.importeRet).toFixed(2),
          id: this.filtroRetenciones.id,
        });
        this.limpiarRetenciones();
      }else{
        this.limpiarRetenciones();
        return this.$store.dispatch('show_snackbar', {
          text: 'La retención a agregar ya existe en la tabla. Revisar.',
          color: 'info',
        });
      }
    },
    quitarRetencion(item){
      this.retencionesFacturaCompra = this.retencionesFacturaCompra.filter(elem => elem.id != item.id);
      this.llamarAcalcular();
    },
    async validaFechaPago(){
      if (!this.filtroPago.fechaPago){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una fecha válida para poder proseguir.',
          color: 'error',
        })
        this.filtroPago.fechaPago = moment(new Date()).format('DD/MM/YYYY')
        return
      }
      // agregado el 03-05-2023 por MMURILLO, valido que la fecha que me estan pasando sea hábil
      this.$store.state.loading = true
      let diaPeticion = await this.$store.dispatch('facturasCompra/validarFecha', {fecha: this.filtroPago.fechaPago})
      this.$store.state.loading = false
      if (diaPeticion.resultado == 1){
        // tengo feriados?
        if (diaPeticion.feriados.length > 0){
          // tengo feriado el día que me dieron
          this.$store.dispatch('show_snackbar', {
            text: 'No se puede seleccionar la fecha ' + this.fechaPago + ' por ser ' + diaPeticion.feriados[0].nombre_feriado,
            color: 'warning',
          })
          // seteo nuevamente la fecha
          this.filtroPago.fechaPago = moment(new Date()).format('DD/MM/YYYY')
          // hago focus en la fecha para que ingrese otra en caso de querer hacerlo
          this.$refs.fechaPagoEstimada.focus()
          return
        }
      }else{
        this.filtroPago.fechaPago = moment(new Date()).format('DD/MM/YYYY')
        this.$store.dispatch('show_snackbar', {
          text: diaPeticion.msj,
          color: 'error',
        })
      }
    },
    validaPorcentaje(){
      if(this.switchFormaPago == false){
        // es vacio el porcentaje?
        if(!this.switchFormaPago && !this.filtroPago.porcentajePago){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe ingresar un porcentaje válido para poder proseguir.',
            color: 'error',
          })
          this.filtroPago.importePago = null;
          return
        }
        this.filtroPago.porcentajePago = parseFloat(this.filtroPago.porcentajePago)
        //parto de un nro redondeado
        this.filtroPago.porcentajePago = roundNumber(this.filtroPago.porcentajePago,2)
        // es negativo o mayor a 100?
        if(this.filtroPago.porcentajePago < 0 || 100 < this.filtroPago.porcentajePago){
          this.$store.dispatch('show_snackbar', {
            text: 'Porcentaje no válido. Debe ser un número entre 1 y 100.',
            color: 'error',
          })
          this.filtroPago.porcentajePago = null;
          this.filtroPago.importePago = null;
          return
        }
        let totalPorcentaje = parseFloat(this.filtroPago.porcentajePago) + parseFloat(this.totalFinalPorcentaje)//porcentajeFinalAcumulado
        // la suma del porcentaje que estoy agregando y el porcentaje total acumulado hasta el momento supera el 100%?
        if (totalPorcentaje > 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Porcentaje no válido. Excede el total de la Factura.',
            color: 'error',
          })
          this.filtroPago.porcentajePago = null;
          this.filtroPago.importePago = null;
          return
        }
        //this.filtroPago.porcentajePago = parseFloat(this.filtroPago.porcentajePago)
        let porcentaje = parseFloat(this.filtroPago.porcentajePago/100)
        this.filtroPago.importePago = porcentaje * parseFloat(this.datosFactura.total);//parseFloat(this.datosFactura.total.toFixed(4))
        this.filtroPago.importePago = parseFloat(this.filtroPago.importePago)//roundNumber(this.filtroPago.importePago,4)//parseFloat(this.filtroPago.importePago.toFixed(4))
        
        // estoy agregando un pago con el que llegamos al 100%?
        if (parseInt(totalPorcentaje) == 100){
          this.filtroPago.importePago = roundNumber((parseFloat(this.datosFactura.total) - parseFloat(this.totalFinalPagos)),2);
        }else{
          this.filtroPago.importePago = roundNumber(this.filtroPago.importePago,2)
        }
      }
    },
    validaImportePago(){
      if(this.switchFormaPago == true){
        if (!this.filtroPago.importePago || parseFloat(this.filtroPago.importePago) < 0 || parseFloat(this.totalTotal) < parseFloat(this.filtroPago.importePago) ){
          this.$store.dispatch('show_snackbar', {
            text: 'Importe no válido. Debe ingresar un importe mayor a 0 y que no sobrepase el valor Total de la Factura.',
            color: 'error',
          })
          this.filtroPago.porcentajePago = null
          this.filtroPago.importePago = null
          return
        }

        if(roundNumber(this.filtroPago.importePago,2) == (roundNumber(this.totalTotal - this.totalFinalPagos,2))){
          this.filtroPago.porcentajePago = roundNumber(100 - this.totalFinalPorcentaje,2)
          return 
        }
        this.filtroPago.importePago = parseFloat(this.filtroPago.importePago);
        this.filtroPago.importePago = roundNumber(this.filtroPago.importePago,2)
        let porcentaje = (this.filtroPago.importePago / parseFloat(this.totalTotal) )*100;
        this.filtroPago.porcentajePago = roundNumber(porcentaje,2)
      }
    },
    quitarPago(item){
      if(item.id == 0){
        const index = this.periodosPagoFacturaCompra.indexOf(item)
        this.periodosPagoFacturaCompra.splice(index,1)
      }else{
        this.periodosPagoFacturaCompra = this.periodosPagoFacturaCompra.filter(elem => elem.id != item.id);
      }
      //this.id_key--;
      this.totalFinalPorcentaje -= item.porcentaje;
      this.totalFinalPagos -= item.importe;
    },
    limpiarFiltroPagos(){
      this.filtroPago = {
        fechaPago: moment(new Date()).format('DD/MM/YYYY'),
        porcentajePago: null,
        importePago: null,
        tipoPago: null,
        formasPago: null,
      }
    },
    faltanCampos(){
      let b = false;
      if(!this.filtroPago.fechaPago || (!this.filtroPago.porcentajePago && this.filtroPago.porcentajePago != 0) || (!this.filtroPago.importePago && this.filtroPago.importePago != 0)
         || !this.filtroPago.tipoPago || !this.filtroPago.formasPago){
        b = true;
      }return b;
    },
    addPago(){
      if(this.detallesFacturaCompra.length > 0){
        // control de valores vacios
        if(this.faltanCampos()){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe completar todos los campos de pago para poder agregar.',
            color: 'warning',
          })
          return
        }
               
        if (roundNumber(this.totalTotal,2) < roundNumber((parseFloat(this.filtroPago.importePago) + parseFloat(this.totalFinalPagos)),2)){
          this.$store.dispatch('show_snackbar', {
            text: 'Importe no válido. Debe ingresar un importe mayor a 0 y que no sobrepase el valor Total de la Factura.',
            color: 'error',
          })
          this.limpiarFiltroPagos();
          return
        }
        if(roundNumber((this.filtroPago.porcentajePago + this.totalFinalPorcentaje),2) > 100){
          this.$store.dispatch('show_snackbar', {
            text: 'El porcentaje sobrepasa el valor Total de la Factura.',
            color: 'error',
          })
          this.limpiarFiltroPagos();
          return
        }

        let acu = parseFloat(this.filtroPago.importePago) + parseFloat(this.totalFinalPagos);
        
        if(roundNumber(acu,2) > roundNumber(this.totalTotal,2)){
          this.$store.dispatch('show_snackbar', {
            text: 'No se puede agregar ya que la suma de los importes de Pago sobrepasan al Total de la Factura.',
            color: 'error',
          })
          this.filtroPago.porcentajePago = null;
          this.filtroPago.importePago = null;
          return
        }        
        let unPago = {
          fecha: this.filtroPago.fechaPago,
          porcentaje: this.filtroPago.porcentajePago,
          compra_fp_nombre: (this.formasPago.filter(elem => elem.forma_pago_id == this.filtroPago.formasPago))[0].forma_pago_nombre, //this.selectedFP.forma_pago_nombre,
          compra_fp_id: this.filtroPago.formasPago,
          importe: this.filtroPago.importePago,
          tipo_pago_id: this.filtroPago.tipoPago,
          tipo_pago_nombre: (this.tiposPagos.filter(elem => elem.tipo_pago_id == this.filtroPago.tipoPago))[0].tipo_pago_nombre,//this.selectedTipoPago.tipo_pago_nombre,
          pago: 0,
          id: 0,
          id_key: this.id_key
        } 
        this.id_key++
        this.periodosPagoFacturaCompra.push(unPago)

        this.totalFinalPorcentaje += unPago.porcentaje;
        this.totalFinalPorcentaje = roundNumber(this.totalFinalPorcentaje,2)

        this.totalFinalPagos += unPago.importe;
        this.totalFinalPagos = roundNumber(this.totalFinalPagos,2)

        this.limpiarFiltroPagos();
        //this.selectedFP = {}
      }else{
        this.$swal.fire({
          icon: 'error',
          title: `Error al agregar pago`,
          text: 'No se cargaron artículos en la Factura. No es posible establecer fechas de pago.'
        })
      }
    },
    validarCampos(){
      let error = {}
      if( !this.datosFactura.empresa_codigo ){
        error.text = 'Debe completar el campo Empresa.'
        error.color = 'warning'
        return error
      }
      if( !this.datosFactura.proveedor_codigo ){
        error.text = 'Debe completar el campo Proveedor.'
        error.color = 'warning'
        return error
      }
      // if( !this.datosFactura.sucursal_codigo ){
      //   error.text = 'Debe completar el campo Sucursal.'
      //   error.color = 'warning'
      //   return error
      // }
      if( !this.datosFactura.fecha ){
        error.text = 'Debe completar el campo fecha.'
        error.color = 'warning'
        return error
      }
      if( !this.datosFactura.tipo_factura || !this.datosFactura.tipo_factura_codigo || !this.datosFactura.tipo_factura_id ){
        error.text = 'Debe completar el campo Tipo  Comprobante.'
        error.color = 'warning'
        return error
      }
      if( !this.datosFactura.emision ){
        error.text = 'Debe completar el campo Emision.'
        error.color = 'warning'
        return error
      }
      if( !this.datosFactura.numero ){
        error.text = 'Debe completar el campo Número.'
        error.color = 'warning'
        return error
      }
      if( !this.datosFactura.moneda_id ){
        error.text = 'Debe completar el campo Moneda.'
        error.color = 'warning'
        return error
      }
      if( !this.datosFactura.fecha_costos ){
        error.text = 'Debe completar el campo Fecha Imp Costos.'
        error.color = 'warning'
        return error
      }
      return error;
    },
    async guardar(){
      let encabezado = {};
      let detalles = [];
      let recepciones = [];
      let ordenes_compra = [];
      let periodos_pago = [];
      let retenciones_especiales = [];

      //si faltan campos en el encabezado
      let error = this.validarCampos();
      if(Object.keys(error).length != 0){
        return this.$store.dispatch('show_snackbar', error)
      }
      //si es multiple y no se agrega el bejerman
      if(this.datosFactura.proveedor_codigo && this.selectedProveedor.multicuenta == 1 && !this.datosFactura.proveedor_bejerman_codigo){
        return this.$store.dispatch('show_snackbar', {
          text: "Se requiere ingresar el proveedor bejerman.",
          color: 'warning',
        })
      }
      //si es DOLAR
      if(this.datosFactura.moneda_id == 2 ){
        //Si no se agrega la cotización
        if(!this.datosFactura.cotizacion){
          return this.$store.dispatch('show_snackbar', {
            text: "Se requiere ingresar Cotización.",
            color: 'warning',
          })
        }else{
          if(this.datosFactura.tipo_factura_codigo == 9 || this.datosFactura.tipo_factura_codigo == 8){
            //Si es N. Débito y No se agregó la New Cotización
            if(this.datosFactura.tipo_factura_codigo == 9 && !this.datosFactura.new_cotizacion){
              return this.$store.dispatch('show_snackbar', {
                text: "Se requiere ingresar Nueva Cotización.",
                color: 'warning',
              })
            }
          }
        }
      }
      //si la factura no tiene detalles
      if((this.datosFactura.tipo_factura_codigo != 9) && this.detallesFacturaCompra.length == 0){
        return this.$store.dispatch('show_snackbar', {
          text: "No es posible grabar sin Artículos agregados.",
          color: 'warning',
        })
      }
      //si la factura no tiene periodos de pago
      if((this.datosFactura.tipo_factura_codigo != 8 && this.datosFactura.tipo_factura_codigo != 9) && this.periodosPagoFacturaCompra.length == 0){
        return this.$store.dispatch('show_snackbar', {
          text: "No es posible grabar sin períodos de pago.",
          color: 'warning',
        })
      }
      //si es o no factura controlo totales de períodos de pago
      if(this.periodosPagoFacturaCompra.length > 0){
        if(this.totalFinalPorcentaje != 100 || roundNumber(this.totalTotal,2) != roundNumber(this.totalFinalPagos,2)){
          return this.$store.dispatch('show_snackbar', {
            text: "No es posible grabar. El total de pagos es distinto al Total de la factura.",
            color: 'warning',
          })
        }
      }

      //carga el encabezado
      encabezado = {
        "fecha": this.datosFactura.fecha,
        "fecha_pago": moment(new Date()).format('DD/MM/YYYY'),
        "fecha_costos": this.datosFactura.fecha_costos,
        "dias_pago": 0,
        "proveedor_codigo": this.datosFactura.proveedor_codigo,
        "sucursal_codigo": cadenaVacia(this.datosFactura.sucursal_codigo) ? null : this.datosFactura.sucursal_codigo,
        "iva": parseFloat(this.acuIva21 + this.acuIva105 + this.acuIva27),//parseFloat((this.acuIva21 + this.acuIva105 + this.acuIva27).toFixed(4)),
        "neto": this.totalNeto,
        "sub_total": this.subTotal,
        "total": this.totalTotal,
        "referencia": this.datosFactura.referencia,
        "percepcion": parseFloat(this.retEspAcu),
        "emision": this.datosFactura.emision,
        "numero": this.datosFactura.numero,
        "tipo_factura": this.datosFactura.tipo_factura, //tipo_letra
        "tipo_factura_codigo": this.datosFactura.tipo_factura_codigo, //comp_tipo_id
        "tipo_factura_id": this.datosFactura.tipo_factura_id, //tipo_id, value del data-table
        "empresa_codigo": this.datosFactura.empresa_codigo,
        "proveedor_bejerman": this.datosFactura.proveedor_bejerman_codigo,
        "impuesto": this.importeImpuestoInternoAcu,
        "fc_asoc": this.datosFactura.fc_asoc,
        "moneda_id": this.datosFactura.moneda_id,
        "cotizacion": this.datosFactura.cotizacion,
        "new_cotizacion": this.datosFactura.new_cotizacion,
      };
      //carga detalles
      detalles = this.detallesFacturaCompra.map(elem => ({
        articulo_codigo: elem.articulo_codigo,
        articulo_proveedor: elem.articulo_cod_barras,
        cantidad: elem.cantidad,
        precio: elem.precio,
        importe: elem.importe,
        neto: elem.neto,
        iva: elem.iva,
        gravamen_codigo: elem.gravamen_codigo,
        formula: elem.formula,
        importe_imp_interno: elem.importe_imp_interno,
        impuesto_interno: elem.tasa_impuesto,
        id: elem.id,//para el crear factura, siempre vendrá en 0.
      }));
      //carga ordenes o recepciones
      if(this.origen){
        if(this.origen == 'recepcion'){
          recepciones = this.selectedRecepcionesOrdenes;
        }else{
          ordenes_compra = this.selectedRecepcionesOrdenes;
        }
      }
      //carga periodos de pago
      periodos_pago = this.periodosPagoFacturaCompra;
      retenciones_especiales = this.retencionesFacturaCompra.map(e => ({
        id: e.id,
        importe: e.importe
      }))

      let res;
      let metodo = '';
      let obj = {
        encabezado: encabezado,
        detalles: detalles,
        recepciones: recepciones,
        ordenes_compra: ordenes_compra,
        periodos_pago: periodos_pago,
        retenciones_especiales: retenciones_especiales
      };
      if(this.nuevo){
        metodo='crearFacturaCompra'  
      }else{
        metodo='actualizarFacturaCompra'
        encabezado.estado_control_id = this.datosFactura.estado_control_id;
        obj = {
          factura_id: this.factura_id,
          encabezado: encabezado,
          detalles: detalles,
          recepciones: recepciones,
          ordenes_compra: ordenes_compra,
          periodos_pago: periodos_pago,
          retenciones_especiales: retenciones_especiales
        }
      }
      this.$store.state.loading = true
      res = await this.$store.dispatch(`facturasCompra/${metodo}`, obj)
      this.$store.state.loading = false
      if(res.resultado == 1){
        this.$swal.fire({
          icon: 'success',
          title: this.nuevo? `Factura creada. Nro.:${res.factura_id}` : `Factura ${this.factura_id} actualizada.` ,
          text: this.nuevo? `Se agregó correctamente la factura de compra.` :` Se actualizó correctamente la factura de compra.`,
          timeout: 4000
        });
        this.nuevo ? this.redigir(res.factura_id) : this.redigir(this.factura_id);
      }else{
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error',
        })
        return
      }
    },
    redigir(id){
      //this.$router.push({ path: `/aud-factura-compra/${id}` });
      if(this.$route.path != `/aud-factura-compra/${id}`){
        router.push({ path: `/aud-factura-compra/${id}` });
      }
      this.nuevo = false;
      this.editar = false;
      setTimeout(() => [location.reload()], 3000)
    },
  },
  computed:{
    retornarCantidad(){
      let acu = 0;
      this.detallesFacturaCompra.forEach(elem => acu += parseInt(elem.cantidad));
      return acu
    },
    retornarRetencionesAcu(){
      let acu = 0;
      this.retencionesFacturaCompra.forEach(elem => acu += parseFloat(elem.importe));
      this.retEspAcu = roundNumber(acu,4);//parseFloat(acu.toFixed(4));
      this.llamarAcalcular();
      return this.retEspAcu;
    },
  },
  components:{
    FechaPickerVue, TextFieldMoneyVue, ModalObtRecepOrdVue, SearchDataTableVue, BtnConfirmarVue,
    ModalElegirArticuloFCVue, SeleccionarArticuloVue, ModalSubirArchivosVue
  },
  watch:{
    editar(val){
      if(!val){
        this.datosFactura = JSON.parse(JSON.stringify(this.datosFacturaCopia))
        this.detallesFacturaCompra = JSON.parse(JSON.stringify(this.detallesFacturaCompraCopia));
        this.retencionesFacturaCompra = JSON.parse(JSON.stringify(this.retencionesFacturaCompraCopia));
        this.periodosPagoFacturaCompra = JSON.parse(JSON.stringify(this.periodosPagoFacturaCompraCopia));
        this.switchFormaPago = false;
      }
    },
    selectedProveedor: function(){
      if(this.selectedProveedor == null || this.selectedProveedor == undefined)
      {  
        this.selectedProveedor = {}; 
        this.selectedTipoFactura = {};
      }
    },
    selectedTipoFactura:{
      handler(){
          if(this.selectedTipoFactura == null || this.selectedTipoFactura == undefined)
            this.selectedTipoFactura = {};
        } ,
      deep: true
    },
    switchFormaPago(val){
      this.filtroPago.porcentajePago = null
      this.filtroPago.importePago = null
    },
  }
}
</script>

<style>

</style>